import useAuth from '../../contexts/AuthContext';
import { useSearchParams } from 'react-router-dom';
import { useGetVerifyCode } from './useGetVerifyCode';
import AddStudentDialog from '../AddStudentDialog/AddStudentDialog';
import { useEffect, useMemo, useState } from 'react';

const AddStudentFromCodePopup = () => {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { data, isLoading } = useGetVerifyCode({
    code: code ?? undefined,
    enabled: user?.role === 'parent',
  });

  useEffect(() => {
    if (data && !isLoading) {
      setDialogOpen(true);
    }
  }, [data]);

  const defaultValues = useMemo(
    () =>
      data
        ? {
            email: data.email,
            fullName: data.fullName,
            gender: data.gender,
            birthday: new Date(data.birthday),
            schoolZipCode: data.schoolZipCode,
            schoolName: data.schoolName,
            gradeLevel: Number(data.gradeLevel),
          }
        : undefined,
    [data],
  );

  return (
    <>
      {data && (
        <AddStudentDialog
          open={isDialogOpen}
          setOpen={setDialogOpen}
          defaultValues={defaultValues}
          linkMode
          studentUserUid={data.userUid}
        />
      )}
    </>
  );
};

export default AddStudentFromCodePopup;
