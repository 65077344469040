import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';

export const ChildRelationshipMap: { [key: string]: string } = {
  mother: 'Mother',
  father: 'Father',
  caregiver: 'Caregiver',
};

export const ChildRelationship = Object.entries(ChildRelationshipMap).map(
  (i) => ({
    label: i[1],
    value: i[0],
  }),
);

export interface ParentData {
  uid?: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  birthday: string;
  country: string;
  street?: string;
  street2?: string;
  state?: string;
  zipCode: string;
  phone: string | undefined;
  childRelationship: string;
  verificationLink: string;
  linkCode?: string | null;
}

interface ParentResponse extends ParentData {
  accessToken: string;
  refreshToken: string;
}

const registerParent = async (
  parentData: ParentData,
): Promise<ParentResponse> => {
  return httpClient.post('/parents/signup', { ...parentData });
};

export const useRegisterParent = (callbacks: {
  onSuccess?: (data: ParentResponse) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();
  const mutation = useMutation(
    (parentData: ParentData) => registerParent(parentData),
    {
      onSuccess: callbacks.onSuccess ?? (() => {}),
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
