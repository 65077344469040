import React, { createContext, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../contexts/AuthContext';
import { UserRole } from '../../types/UserProfile';

interface RegisterProviderProps {
  children: ReactNode;
}

interface RegisterContextProps {
  registrationSteps: {
    active: number;
    total: number;
  };
  goToNextStep: () => void;
  goToPrevStep: () => void;
  updateRegistrationSteps: (active: number) => void;
}

export const RegisterContext = createContext<RegisterContextProps>({
  registrationSteps: {
    active: 0,
    total: 0,
  },
  goToNextStep: () => {},
  goToPrevStep: () => {},
  updateRegistrationSteps: () => {},
});

export const RegisterProvider: React.FC<RegisterProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const routes = [
    '/register',
    '/register/students',
    '/register/survey/intro',
    '/register/survey?question=1',
    '/register/survey?question=2',
    '/register/survey?question=3',
    '/register/survey?question=4',
    '/subscriptions/parent',
    '/register/invitations',
  ];

  const registrationSteps = {
    active: 1,
    total: routes.length,
  };

  const { user, isLoading: isLoadingUser } = useAuth();
  useEffect(() => {
    if (!isLoadingUser) {
      if (!user && registrationSteps.active > 1) {
        navigate(`/${location.search}`);
      }
      if (user && user.role === UserRole.STUDENT) {
        navigate('/');
      }
      if (
        user &&
        user.hasFinishedRegistration &&
        location.pathname !== '/register/invitations'
      ) {
        navigate(`/${location.search}`);
      }
    }
  }, [isLoadingUser, registrationSteps.active]);

  const goToNextStep = () => {
    const nextStep = registrationSteps.active + 1;
    if (nextStep <= routes.length) {
      // scroll to top
      window.scrollTo(0, 0);
      navigate(routes[nextStep - 1]);
    }
  };

  const goToPrevStep = () => {
    const prevStep = registrationSteps.active - 1;
    if (prevStep >= 1) {
      // scroll to top
      window.scrollTo(0, 0);
      navigate(routes[prevStep - 1]);
    }
  };

  const updateRegistrationSteps = (active: number) => {
    if (active < 1 || active > registrationSteps.total) return;
    registrationSteps.active = active;
  };

  return (
    <RegisterContext.Provider
      value={{
        registrationSteps,
        goToNextStep,
        goToPrevStep,
        updateRegistrationSteps,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export function useRegisterContext(): RegisterContextProps {
  return React.useContext(RegisterContext);
}
