import { Button } from 'primereact/button';
import React, { FC, useEffect, useMemo, useState } from 'react';
import ArrowLeft from '../../icons/Arrows/ArrowLeft';
import ArrowRight from '../../icons/Arrows/ArrowRight';
import { getStaticCoachingSuggestions } from './useStaticSuggestions';

interface Props {
  onGetSuggestionClick: () => void;
  isGeneral?: boolean;
}

export const StrugglingBanner: FC<Props> = ({
  onGetSuggestionClick,
  isGeneral,
}) => {
  const [staticSuggestions, setStaticSuggestions] = useState<string[]>([]);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);

  const getStaticSuggestions = useMemo(
    () => async () => {
      const list = await getStaticCoachingSuggestions();
      setStaticSuggestions(list || []);
    },
    [],
  );

  useEffect(() => {
    getStaticSuggestions();
  }, [getStaticSuggestions]);

  return (
    <div className="w-full bg-new-yellow p-[30px] rounded-[10px] shrink-0 gap-[20px] flex flex-col">
      <img
        src="/images/suggestion-logo.png"
        alt=""
        className="m-auto block max-w-[313px] w-full"
      />
      <div className="grid grid-cols-3 gap-4">
        <h2 className="text-2xl m-0 col-span-2">Work Setup?</h2>
        <div className="pt-1">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            //eslint-disable-next-line no-script-url
            href="javascript:void(0)"
            className="pr-2"
            onClick={() => {
              let newIndex = currentSuggestionIndex - 1;
              if (newIndex < 0) {
                newIndex = staticSuggestions.length - 1;
              }
              setCurrentSuggestionIndex(newIndex);
            }}
          >
            <ArrowLeft />
          </a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            //eslint-disable-next-line no-script-url
            href="javascript:void(0)"
            onClick={() => {
              let newIndex = currentSuggestionIndex + 1;
              if (newIndex >= staticSuggestions.length) {
                newIndex = 0;
              }
              setCurrentSuggestionIndex(newIndex);
            }}
          >
            <ArrowRight />
          </a>
        </div>
      </div>
      <p className="text-base text-grey3 m-0">
        {staticSuggestions[currentSuggestionIndex]}
      </p>
      {!isGeneral && (
        <Button
          className="mt-[10px] mb-[20px] w-full text-sm md:text-base text-center justify-center"
          onClick={onGetSuggestionClick}
        >
          Struggling?
        </Button>
      )}
    </div>
  );
};
