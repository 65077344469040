
import { AnimatePresence, motion } from "framer-motion"
import { Button } from "primereact/button"

interface DownloadAppBannerProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export function DownloadAppBanner({ open, setOpen }: DownloadAppBannerProps) {

  const handleDismiss = () => {
    setOpen(false)
  }

  return (
    <AnimatePresence>
      {open && (
        <>
          {/* Grey overlay */}
          <motion.div
            className="fixed inset-0 bg-black/50 z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleDismiss}
          />

          {/* Top banner with integrated pointer */}
          <motion.div
            className="fixed top-0 left-0 right-0 z-50 pt-[64px] px-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="relative max-w-xl mx-auto">
              {/* Animated arrow pointing up */}
              <motion.div
                className="absolute top-[-56px] right-0 transform -translate-x-1/2"
                animate={{ y: [-5, -12, -5] }}
                transition={{
                  repeat: Number.POSITIVE_INFINITY,
                  duration: 1.5,
                }}
              >
                <svg width="70" height="70" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 19V5M12 5L6 11M12 5L18 11"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </motion.div>

              {/* Main banner content */}
              <div className="bg-white rounded-lg shadow-lg border-2 border-primary overflow-hidden">
                <div className="relative p-4">
                <Button
                    className="absolute top-[8px] right-[12px]"
                    icon="pi pi-times"
                    rounded
                    text
                    severity="secondary"
                    aria-label="Close modal"
                    onClick={handleDismiss}
                />

                  <div className="flex items-center gap-4 pt-1">

                    <div className="flex-1">
                      <h3 className="text-lg font-bold">Download ULURU from the App Store!</h3>
                      <p className="text-sm text-muted-foreground mt-1">
                        Tap "Get" to download the app to your device.
                      </p>
                    </div>

                    <div className="flex-shrink-0 self-end mt-[35px]">
                      <Button onClick={handleDismiss} className="whitespace-nowrap">
                        Got it
                      </Button>
                    </div>
                  </div>
                </div>

              
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

