import RegistrationProgress from '../../components/RegistrationProgress/RegistrationProgress';
import React, { useState, useEffect } from 'react';
import PreviousNextButtons from '../../components/PreviousNextButtons/PreviousNextButtons';
import { isIOS, isSafari } from 'react-device-detect';
import { useRegisterStudentContext } from '../../layouts/RegisterStudentLayout/RegisterStudentContext';
import Copyright from '../../components/Copyright';
import { DownloadAppBanner } from './DownloadAppBanner';
import { APP_DOWNLOAD_TOKEN_KEY } from '../auth/CreateStudentAccount/CreateStudentAccount';

const HowToDownload = () => {
  const { registrationSteps, goToNextStep, goToPrevStep } =
    useRegisterStudentContext();
  const [openBanner, setOpenBanner] = useState(false);
  useEffect(() => {
    if (isIOS && isSafari) {
      const existingTag = document.querySelector('meta[name="apple-itunes-app"]');
      
      if (!existingTag) {
        const appDownloadToken = localStorage.getItem(APP_DOWNLOAD_TOKEN_KEY);
        const metaTag = document.createElement('meta');
        metaTag.name = 'apple-itunes-app';
        metaTag.content = `app-id=6740403493, app-argument=${window.origin}/?appDownloadToken=${appDownloadToken}`;
        document.head.appendChild(metaTag);
        setOpenBanner(true);

        // Force reload to show the banner
        if (!sessionStorage.getItem('bannerShown')) {
          sessionStorage.setItem('bannerShown', 'true');
          window.location.reload();
        }
      }
    }
  }, []);



  return (
    <div className="relative pb-[80px] bg-white p-[30px] px-[50px] md:p-20 h-full flex flex-col gap-[40px] grow">
      <DownloadAppBanner open={openBanner} setOpen={setOpenBanner} />
      <div className="w-fit">
        <RegistrationProgress registrationSteps={registrationSteps} />
      </div>
      <h2 className="text-2xl md:text-4xl m-0">
        Download ULURU from the App Store!
      </h2>
      <div className="hidden md:flex flex-col">
        <h3 className="text-base md:text-[20px] text-grey3">
          Scan this QR code
        </h3>
        <a
          href="https://apps.apple.com/us/app/uluru-app/id6740403493"
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center"
        >
          <img
            src="/images/app-store-qr-code.svg"
            alt="App Store link QR code"
            className="block w-[60%] max-2-[600px]"
          />
        </a>
      </div>

      <div className="grow">
        <div className="flex md:hidden flex-col h-full">
          <a
            href="https://apps.apple.com/us/app/uluru-app/id6740403493"
            target="_blank"
            rel="noreferrer"
            className="flex flex-col items-center"
          >
            <img
              src="/images/download-on-the-app-store-badge.svg"
              alt="App Store link"
              className="block w-full max-w-[250px]"
            />
          </a>
        </div>

        <p className="text-base md:text-lg text-grey3">
          *Remember after you install the app select the option “We have met
          before” and use your email and password log in.
        </p>

        <p className="text-base md:text-lg text-grey3 font-semibold">
          WELCOME TO MORE FREEDOM!
        </p>
      </div>
      <PreviousNextButtons
        nextStep={goToNextStep}
        hideIconNext
        nextLabel={'Finish'}
        previousStep={goToPrevStep}
      />
      <Copyright className="absolute left-0 right-0 bottom-0 p-[30px] px-[50px] md:px-[80px]" />
    </div>
  );
};

export default HowToDownload;
