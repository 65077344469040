import RegisterStudentForm from '../RegisterStudentForm';
import { Button } from 'primereact/button';
import SaveIcon from '../../icons/SaveIcon';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import {
  RegisterStudentFormRef,
  StudentFields,
} from '../RegisterStudentForm/RegisterStudentForm';
import { createStudent } from '../../pages/ManageSubscription/useManageSubscriptions';
import { useSnackbar } from '../../contexts/SnackbarContext';
import {
  purchaseStripe,
  useGetSubscriptionDetails,
} from '../../pages/ParentSubscription/useParentSubscription';
import { useUseLinkCode } from '../AddStudentFromCodePopup/useUseLinkCode';
import { useSearchParams } from 'react-router-dom';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  defaultValues?: StudentFields;
  linkMode?: boolean;
  studentUserUid?: string;
}

const AddStudentDialog = ({
  open,
  setOpen,
  defaultValues,
  linkMode,
  studentUserUid,
}: Props) => {
  const { openSnackbar } = useSnackbar();
  const { data: subscriptionDetails } = useGetSubscriptionDetails();
  const [isSavingStudent, setIsSavingStudent] = useState<boolean>(false);
  const [studentForm] = useState<{
    id: number;
    ref: RegisterStudentFormRef | null;
    defaultValues?: StudentFields;
  }>({ id: 0, ref: null });
  const [createStudentError, setCreateStudentError] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');
  const useLinkCodeMutation = useUseLinkCode({
    onSuccess: () => {
      // setSearchParams({});
      // openSnackbar({
      //   message: 'Student added successfully',
      //   type: 'success',
      // });
    },
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setIsSavingStudent(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const purchaseSubscription = async (userUid: string) => {
    const result = await purchaseStripe(
      subscriptionDetails?.stripePublicKey ?? '',
      {
        [userUid]: true,
      },
      '/manage-subscriptions',
      '/register/invitations',
    );
    setIsSavingStudent(false);
    setSearchParams({});

    if (!result) {
      openSnackbar({
        type: 'error',
        message:
          'There are some issues with the payment gateway, please contact the administrator',
      });
    }
  };
  const handleCreateStudentAndPurchaseStripe = async () => {
    setIsSavingStudent(true);
    const newStudent = await new Promise<StudentFields>((resolve) => {
      studentForm?.ref?.handleSubmit?.(
        (data: StudentFields) => resolve(data),
        () => {
          setIsSavingStudent(false);
        },
      )();
    });

    if (!newStudent) {
      openSnackbar({
        type: 'error',
        message: 'Please select at least one student',
      });
      setIsSavingStudent(false);
      return;
    }

    try {
      if (linkMode && studentUserUid) {
        await useLinkCodeMutation.mutateAsync({
          linkCode: code ?? '',
          fullName: newStudent.fullName,
          gender: newStudent.gender,
          birthday: newStudent.birthday,
          schoolZipCode: newStudent.schoolZipCode,
          schoolName: newStudent.schoolName,
          gradeLevel: newStudent.gradeLevel.toString(),
        });

        await purchaseSubscription(studentUserUid);
      } else {
        const student = await createStudent(newStudent);

        await purchaseSubscription(student.userUid);
      }
    } catch (e: any) {
      setIsSavingStudent(false);
      setCreateStudentError(
        e?.response?.data?.message || 'Something went wrong',
      );
    }
  };

  useEffect(() => {
    if (defaultValues) {
      studentForm.defaultValues = defaultValues;
    }
  }, [defaultValues]);

  return (
    <Dialog
      header="Add student"
      visible={open}
      onHide={() => setOpen(false)}
      contentStyle={{
        overflowY: 'auto',
        height: '100%',
        paddingBottom: '0',
      }}
      style={{
        maxHeight: 'auto',
      }}
      draggable={false}
      resizable={false}
    >
      <div className="min-w-[50vw] table max-h-[95vh] overflow-y-auto pb-[10px]">
        <RegisterStudentForm
          ref={(ref) => {
            studentForm.ref = ref;
          }}
          onValidityChange={() => {}}
          defaultValues={studentForm.defaultValues}
          disableEmail={!!linkMode}
          formHeight="640px"
        >
          <div>
            {createStudentError && (
              <p className={'input-error-text'}>{createStudentError}</p>
            )}

            <Button
              type="button"
              onClick={handleCreateStudentAndPurchaseStripe}
              className="p-button p-component float-right p-[12px] px-[40px] text-center flex justify-center gap-[10px] align-right text-sm md:text-base mt-7"
              loading={isSavingStudent}
            >
              <div>Proceed to Subscription</div>
              <SaveIcon />
            </Button>
          </div>
        </RegisterStudentForm>
      </div>
    </Dialog>
  );
};

export default AddStudentDialog;
