import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';

interface Response {
  email: string;
  linkCode: string;
  fullName: string;
  gender: string;
  birthday: Date;
  schoolZipCode: string;
  schoolName: string;
  gradeLevel: string;
  userUid: string;
}
export const getVerifyCode = async ({
  queryKey,
}: {
  queryKey: (string | undefined)[];
}): Promise<Response> => {
  const [, code] = queryKey;

  return httpClient.get<void, Response>(`/link-code/parent/verify/`, {
    params: {
      code,
    },
  });
};

export const useGetVerifyCode = ({
  code,
  enabled = true,
}: {
  code?: string;
  enabled?: boolean;
}) => {
  const useQueryResult = useQuery(
    ['/link-code/parent/verify', code],
    getVerifyCode,
    {
      enabled: enabled && !!code,
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  );

  return {
    ...useQueryResult,
  };
};
