import { httpClient } from '../../api/http/http-client';
import { useQuery } from '@tanstack/react-query';
import { Student } from '../../types/Student';
import { Parent } from '../../types/Parent';

export enum RecipientRole {
  PARENT = 'parent',
  STUDENT = 'student',
}
export enum BindingRequestStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface BindingRequest {
  uid: string;
  studentUid: string;
  student: Student;
  parentUid: string;
  parent: Parent;
  recipientRole: RecipientRole;
  status: BindingRequestStatus;
  requestedAt: Date;
  respondedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  studentEmail?: string;
}

export const getCreatedBindings = async (): Promise<BindingRequest[]> => {
  return httpClient.get<void, BindingRequest[]>('/binding-request/created', {});
};

export const useGetCreatedBindings = () => {
  const queryResult = useQuery(
    ['/binding-request/created'],
    getCreatedBindings,
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  );

  return queryResult;
};
