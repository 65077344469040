import { FC, useRef, useState } from 'react';
import MessageIcon from 'src/app/icons/MessageIcon';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { AuthorMessage, isUpdating, MessageEntity } from './useChatWindow';
import { formatDistance } from 'date-fns';
import useAuth from '../../contexts/AuthContext';
import { UserRole } from '../../types/UserProfile';
import { useChatWebhooks } from './useChatWebhooks';
import MessageComponent from './MessageComponent';
import CalculatorKeyboard from './CalculatorKeyboard';

interface Props {
  isChatOpen: boolean;
  studentUid: string | undefined;
  setIsChatOpen: (value: boolean) => void;
}

const ChatPlaceholder = () => {
  return (
    <div className="flex flex-col items-center px-[16px]">
      <img
        className="w-[107px] mb-[30px]"
        src="/images/uluru-logo/smiling.svg"
        alt="Uluru logo"
      />
      <h3 className="m-0 mb-[10px] text-xl text-center text-white">
        How can I help You?
      </h3>
      <p className="text-base text-center text-blue4 m-0 mb-[45px]">
        Let's work together on breaking down the problem into steps!
      </p>
      <p className="text-base text-center text-blue4 m-0">
        Please note, chat history is cleaned each 3 days.
      </p>
    </div>
  );
};
const MyMessage: FC<{ message: MessageEntity }> = ({ message }) => {
  const createdAt = new Date(message.createdAt);

  return (
    <div className="flex flex-col items-end">
      {message && (
        <div className="flex flex-col items-end gap-[8px]">
          <div className="w-full justify-between text-sm">
            <div className="text-white">You</div>

            <div className="text-blue4">
              {formatDistance(createdAt.getTime(), new Date(), {
                addSuffix: true,
              })}
            </div>
          </div>
          {message.message && (
            <div
              className={`bg-blue4 py-[10px] px-[15px] text-grey3 max-w-[350px] ${
                message.message.includes(' ') ? '' : 'break-all'
              }`}
              style={{
                borderRadius: '24px 0px 24px 24px',
              }}
            >
              {message.message}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const UluruMessage: FC<{
  message?: MessageEntity;
}> = ({ message }) => {
  const createdAt = message ? new Date(message.createdAt) : null;
  const isLoading = !message?.message;

  return (
    <div className="flex gap-[12px]">
      <img
        className="w-[32px] h-[32px]"
        src="/images/uluru-logo/avatar.png"
        alt="Uluru"
      />
      <div className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-[8px]">
          <div className="w-full justify-between text-sm">
            <div className="text-white">Uluru</div>
            <div className="text-blue4">
              {createdAt &&
                formatDistance(createdAt.getTime(), new Date(), {
                  addSuffix: true,
                })}
            </div>
          </div>
          {message?.message && !isLoading && (
            <MessageComponent message={message.message} />
          )}
          {isLoading && (
            <div
              className="bg-new-yellow py-[10px] px-[15px] text-black1 max-w-[254px]"
              style={{
                borderRadius: '0px 24px 24px 24px',
              }}
            >
              <div className="w-[24px] h-[24px] flex items-center justify-center">
                <div className="dot-flashing"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ChatWindow: FC<Props> = ({
  isChatOpen,
  setIsChatOpen,
  studentUid,
}) => {
  const { user } = useAuth();
  const [inputText, setInputText] = useState('');
  const [openKeyboard, setOpenKeyboard] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { sendChatMessage, messages, isConnected, isFetchingMessage } =
    useChatWebhooks({ studentUid });
  //Autoscroll to bottom
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const sendMessage = () => {
    sendChatMessage(inputText);
    setInputText('');
  };

  const lastMessage = messages?.[0];
  const messagesWithoutLast = messages?.slice(1);
  const displayLoading = lastMessage && isUpdating(messages);

  return (
    <div
      className="fixed top-0 right-0 bottom-0 bg-blue1 max-w-[calc(100%-40px)] w-[573px] h-[calc(100vh - 60px)] z-50 flex flex-col justify-between rounded-l-[12px] md:rounded-r-[12px] md:m-[30px] overflow-hidden"
      style={{
        transform: isChatOpen ? 'translateX(0)' : 'translateX(110%)',
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <div className="bg-blue5 rounded-tl-[12px] md:rounded-tr-[12px] py-[12px] px-[20px] text-white">
        <div className="flex justify-between items-center gap-[10px]">
          <MessageIcon />
          <h5 className="m-0 text-base font-normal mr-auto h-fit">
            ULURU Chat
          </h5>
          <i
            className="pi pi-times text-xl cursor-pointer"
            onClick={() => setIsChatOpen(false)}
          />
        </div>
      </div>
      {messages?.length === 0 && <ChatPlaceholder />}
      <div className="flex items-center justify-center gap-[20px] text-white text-sm px-[16px] text-center m-0 pt-[15px] pb-[10px]">
        <div className="relative grow">
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: 0,
              width: '100%',
              height: '1px',
              backgroundColor: '#FFFFFF',
              transform: 'translateY(-50%)',
              opacity: 0.2,
            }}
          ></div>
        </div>
        <div className="pi pi-history mr-[-10px] text-xl" />
        <div>Only 3 days history available</div>
        <div className="relative grow">
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: 0,
              width: '100%',
              height: '1px',
              backgroundColor: '#FFFFFF',
              transform: 'translateY(-50%)',
              opacity: 0.2,
            }}
          ></div>
        </div>
      </div>
      <div className="flex flex-col-reverse gap-[24px] p-[20px] pb-[20px] h-full overflow-x-hidden overflow-y-auto">
        <div ref={messagesEndRef} />
        {displayLoading && <UluruMessage />}
        {lastMessage &&
          (lastMessage.author === AuthorMessage.STUDENT ? (
            <MyMessage message={lastMessage} />
          ) : (
            <UluruMessage message={lastMessage} />
          ))}
        {messagesWithoutLast?.map?.((message) => (
          <div key={message.uid}>
            {message && message?.author === AuthorMessage.STUDENT ? (
              <MyMessage message={message} />
            ) : (
              <UluruMessage message={message} />
            )}
          </div>
        ))}
      </div>

      <p className="text-blue4 text-[10px] px-[16px] text-center m-0 pt-[10px] pb-[20px]">
        Follow up with your teacher and check whether this solution is correct
      </p>
      {user?.role === UserRole.STUDENT && (
        <div className="flex bg-blue5 items-center">
          <InputTextarea
            className="disable-borders text-blue4 placeholder-blue4 !rounded-[0px] !border-0 bg-blue5 !py-[22px]"
            placeholder="Type your question here... "
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            // cols={1}
            rows={1}
            autoResize
            maxLength={200}
            ref={inputRef}
          />
          <Button
            className="disable-borders !bg-transparent border-none text-white w-[40px] h-[40px] p-[8px] rounded-[10px] mx-[10px]"
            icon="pi pi-calculator text-white opacity-70 text-2xl"
            // onClick={sendMessage}
            // loading={!isConnected || isFetchingMessage}
            onClick={() => setOpenKeyboard(!openKeyboard)}
          ></Button>
          {inputText.length > 1 && (
            <Button
              className="disable-borders !bg-white border-0 text-blue1 w-[40px] h-[40px] p-[8px] rounded-[10px] mx-[10px]"
              icon="pi pi-send text-blue1 text-2xl"
              onClick={sendMessage}
              loading={!isConnected || isFetchingMessage}
            ></Button>
          )}
        </div>
      )}
      <div
        style={{
          height: openKeyboard ? '275px' : '0px',
          transition: 'height 0.3s ease',
        }}
      >
        <CalculatorKeyboard
          onInputChange={(value) => {
            setInputText((prev) => prev + value);
            setTimeout(() => {
              if (!inputRef.current) return;
              inputRef.current?.focus?.();
              const length = inputRef.current.value.length;
              inputRef.current.setSelectionRange(length, length);
            }, 100);
          }}
        />
      </div>
    </div>
  );
};
