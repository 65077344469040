import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import React, { FC, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Welcome from '../pages/welcome/Welcome';
import MainLayout from '../layouts/MainLayout';
import Login from '../pages/auth/Login/Login';
import ResetPassword from '../pages/auth/ResetPassword/ResetPassword';
import SurveySteps from '../pages/SurveySteps/SurveySteps';
import RegisterLayout from '../layouts/RegisterLayout/RegisterLayout';
import IntroSurveyStep from '../components/RegisterSteps/IntroSurveyStep';
import RegisterParent from '../pages/RegisterParent/RegisterParent';
import InvitationLinkStep from '../pages/InvintationLinks/InvitationLinks';
import HowToDownload from '../pages/HowToDownload';
import CreateStudentAccount from '../pages/auth/CreateStudentAccount';
import RegisterStudent from '../pages/RegisterStudent/RegisterStudent';
import RegisterStudentLayout from '../layouts/RegisterStudentLayout/RegisterStudentLayout';
import WelcomeStudent from '../pages/WelcomeStudent/WelcomeStudent';
import MyMotivation from '../pages/MyMotivation/MyMotivation';
import NeuropsychTest from '../pages/NeuropsychTest';
import { ParentHome } from '../pages/ParentHome/ParentHome';
import ParentLayout from '../layouts/ParentLayout';
import StudentLayout from '../layouts/StudentLayout';
import { StudentHome } from '../pages/StudentHome/StudentHome';
import TestResult from '../pages/TestResult/TestResult';
import { Profile } from '../pages/Profile/Profile';
import ChangePassword from '../pages/auth/ChangePassword/ChangePassword';
import ParentSubscription from '../pages/ParentSubscription/ParentSubscription';
import Grey from '../layouts/GreyLayout';
import { ManageSubscriptions } from '../pages/ManageSubscription/ManageSubscriptions';
import AssignmentsListPage from '../components/AssignmentsListPage';
import { LMS } from '../pages/LMS/LMS';
import { AssignmentDetailsPage } from '../components/AssignmentDetailsPage/AssignmentDetailsPage';
import { SnackbarProvider } from '../contexts/SnackbarContext';
import NeuropsychTestIntro from '../pages/NeuropsychTestIntro';
import AdminLayout from '../layouts/AdminLayout';
import { FamilyAccountsList } from '../pages/FamilyAccountsList/FamilyAccountsList';
import FamilyAccountPage from '../pages/FamilyAccountPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { SchoologyVerify } from '../pages/LMS/Schoology/Verify';
import { SchoologyLogin } from '../pages/LMS/Schoology/Login';

const NotFound = lazy(() => import('../components/NotFound/NotFound'));

const AppRoutes: FC = () => {
  return (
    <ErrorBoundary>
      <Router>
        <Routes>
          <Route element={<SnackbarProvider />}>
            <Route element={<MainLayout />}>
              <Route path="/" element={<Welcome />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/schoology/*">
                <Route path="login" element={<SchoologyLogin />} />
                <Route path="verify" element={<SchoologyVerify />} />
              </Route>
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/register/*" element={<RegisterLayout />}>
                <Route index element={<RegisterParent />} />
                <Route path="students" element={<RegisterStudent />} />
                <Route path="survey/intro" element={<IntroSurveyStep />} />
                <Route path="survey" element={<SurveySteps />} />
                <Route path="invitations" element={<InvitationLinkStep />} />
              </Route>
              <Route
                path="/student/register/:code/*"
                element={<RegisterStudentLayout />}
              >
                <Route index element={<WelcomeStudent />} />
                <Route path="signup" element={<CreateStudentAccount />} />
                <Route path="how-to-download" element={<HowToDownload />} />
                <Route path="my-motivation" element={<MyMotivation />} />
              </Route>
            </Route>
            <Route path="subscriptions" element={<Grey />}>
              <Route path="parent" element={<ParentSubscription />} />
            </Route>
            <Route path="/parent/*" element={<ParentLayout />}>
              <Route path="home" element={<ParentHome />} />
              {/*<Route*/}
              {/*  path="neuro-test/:studentUserId/intro"*/}
              {/*  element={<NeuropsychTestIntro />}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path="neuro-test/:studentUserId"*/}
              {/*  element={<NeuropsychTest />}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path="neuro-test/result/:resultUid"*/}
              {/*  element={<TestResult />}*/}
              {/*/>*/}
              <Route
                path="assignments/:studentId"
                element={<AssignmentsListPage />}
              />
              <Route
                path="assignment/:id"
                element={<AssignmentDetailsPage />}
              />
            </Route>
            <Route path="/student/*" element={<StudentLayout />}>
              <Route path="home" element={<StudentHome />} />
              {/*<Route*/}
              {/*  path="neuro-test/intro"*/}
              {/*  element={<NeuropsychTestIntro />}*/}
              {/*/>*/}
              {/*<Route path="neuro-test" element={<NeuropsychTest />} />*/}
              {/*<Route*/}
              {/*  path="neuro-test/result/:resultUid"*/}
              {/*  element={<TestResult />}*/}
              {/*/>*/}
              <Route path="assignments" element={<AssignmentsListPage />} />
              <Route
                path="assignment/:id"
                element={<AssignmentDetailsPage />}
              />
            </Route>
            <Route path="/admin/*" element={<AdminLayout />}>
              <Route path="family-accounts" element={<FamilyAccountsList />} />
              <Route
                path="family-accounts/:parentUid"
                element={<FamilyAccountPage />}
              />
              <Route
                path="neuro-test/result/:resultUid"
                element={<TestResult />}
              />
            </Route>
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/manage-subscriptions"
              element={<ManageSubscriptions />}
            />
            <Route path="/lms" element={<LMS />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </ErrorBoundary>
  );
};

export default AppRoutes;
