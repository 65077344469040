import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { httpClient } from '../../api/http/http-client';

interface Props {
  name: string;
  zipCodeName?: string;
}

interface School {
  name: string;
  zipcode: string;
}

interface SchoolResponse {
  data: School[];
  total: number;
  page: number;
}

export const getSchoolData = async (
  name: string,
  zipCode: string,
): Promise<SchoolResponse> => {
  return httpClient.get<void, SchoolResponse>('/schools/list', {
    params: {
      name,
      zipCode,
      page: 0,
      limit: 100,
    },
  });
};

const SchoolSelectDropdown: FC<Props> = ({ name, zipCodeName }) => {
  const {
    clearErrors,
    setValue: setFormValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const defaultValue = watch(name) || '';
  const [suggestions, setSuggestions] = useState<
    { value: string; label: string }[]
  >([]);
  const [value, setValue] = useState<string>(defaultValue);
  const [zipCodeValue, setZipCodeValue] = useState<string>(
    zipCodeName ? watch(zipCodeName) || '' : '',
  );

  const fieldError = errors[name];
  const message = fieldError?.message?.toString();
  const zipCode = zipCodeName ? watch(zipCodeName) : undefined;

  useEffect(() => {
    if (zipCode !== zipCodeValue) {
      setSuggestions([]);
      setValue('');
      setZipCodeValue(zipCode || '');
      setFormValue(name, '');
    }
  }, [zipCode]);

  // Sync with form default value when it changes
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSearch = async (e: any) => {
    const query = e.query;
    const res = await getSchoolData(query, zipCode);
    setSuggestions(
      res?.data?.map?.((i) => ({ value: i.name, label: i.name })) || [],
    );
  };

  return (
    <div className={`label-container ${fieldError ? 'error' : ''}`}>
      <AutoComplete
        field="label"
        value={value}
        disabled={!!zipCodeName && zipCodeValue.length < 3}
        suggestions={suggestions}
        completeMethod={handleSearch}
        delay={500}
        placeholder={'School name'}
        inputStyle={{
          color: fieldError ? 'red' : undefined,
          width: '100%',
        }}
        className="w-full"
        onChange={(e) => {
          const value =
            typeof e.value === 'string' ? e.value : e?.value?.value || '';
          setValue(value);
          setFormValue(name, value);
          clearErrors(name);
        }}
      />
      {message && <div className="input-error-text">{message}</div>}
    </div>
  );
};

export default SchoolSelectDropdown;
