import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import EmailInput from '../../components/EmailInput';
import Input from '../../components/Input';
import DateInput from '../../components/DateInput';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import RadioInput from '../../components/RadioInput';
import { ErrorMessages } from '../../constants/messages';
import { UseFormHandleSubmit } from 'react-hook-form/dist/types/form';
import { emailValidationExists } from '../../types/form';
import { Button } from 'primereact/button';
import { useCheckBinding } from './useCheckBinding';
import { useCreateBinding } from './useCreateBinding';
import SchoolSelectDropdown from './SchoolSelectDropdown';

export interface StudentFields {
  uid?: string;
  fullName: string;
  birthday: Date;
  email: string;
  schoolZipCode: string;
  schoolNameDropdown?: string;
  schoolName: string;
  gradeLevel: number;
  gender: string;
  finishedRegistration?: boolean;
  hasCreatedBinding?: boolean;
}

const minAgeDate = new Date(
  new Date().setFullYear(new Date().getFullYear() - 20),
);

const maxAgeDate = new Date(
  new Date().setFullYear(new Date().getFullYear() - 11),
);

const ageErrorMessage =
  'Sorry, Uluru platform is now more helpful for middle and high school students aged 11-18';

const getShapeSchema = (disableEmail: boolean) => {
  return yup.object().shape({
    fullName: yup
      .string()
      .required(ErrorMessages.Required('Full Name'))
      .max(80, ErrorMessages.TooLong('Full Name', 80)),
    birthday: yup
      .date()
      .required(ErrorMessages.Required("Student's date of birth"))
      .test(
        'is-age-valid',
        ageErrorMessage,
        (value) => value >= minAgeDate && value <= maxAgeDate,
      ),
    gender: yup.string().required(ErrorMessages.Required('Gender')),
    email: disableEmail ? yup.string().required() : emailValidationExists,
    schoolZipCode: yup
      .string()
      .required(ErrorMessages.Required('School zip code'))
      .matches(/^\d{3,5}$/, 'School zip code must be between 3 and 5 digits'),
    schoolNameDropdown: yup.string(),
    schoolName: yup
      .string()
      .required(ErrorMessages.Required('School name'))
      .max(80, ErrorMessages.TooLong('School name', 80)),
    gradeLevel: yup
      .number()
      .integer(ErrorMessages.FromTo('Grade level', 1, 12))
      .positive(ErrorMessages.FromTo('Grade level', 1, 12))
      .typeError(ErrorMessages.FromTo('Grade level', 1, 12))
      .min(1, ErrorMessages.FromTo('Grade level', 1, 12))
      .max(12, ErrorMessages.FromTo('Grade level', 1, 12))
      .required(ErrorMessages.Required('Grade level')),
    finishedRegistration: yup.boolean(),
  });
};

export interface RegisterStudentFormProps {
  onValidityChange: (isValid: boolean) => void;
  defaultValues?: Partial<StudentFields>;
  isValid?: boolean;
  children?: React.ReactNode;
  disableEmail?: boolean;
  formHeight?: string;
}

export interface RegisterStudentFormRef extends RegisterStudentFormProps {
  handleSubmit: UseFormHandleSubmit<StudentFields> | null;
}

export const GendersMap: { [key: string]: string } = {
  male: 'Male',
  female: 'Female',
  other: 'Non-binary',
};

export const GendersList = Object.entries(GendersMap).map((i) => ({
  label: i[1],
  value: i[0],
}));
export const RegisterStudentForm = forwardRef<
  RegisterStudentFormRef,
  RegisterStudentFormProps
>((props, ref) => {
  const [hasCreatedBinding, setHasCreatedBinding] = useState(false);
  const createBindingMutation = useCreateBinding({
    onSuccess: () => {
      setHasCreatedBinding(true);
    },
  });
  const { data: bindingData, checkEmail } = useCheckBinding();
  const formMethods = useForm<StudentFields>({
    resolver: yupResolver(getShapeSchema(!!props.disableEmail)),
    defaultValues: props.defaultValues,
  });
  const { handleSubmit, setValue, getValues } = formMethods;

  const internalRef = useRef<HTMLFormElement>(null);

  useImperativeHandle(ref, () => ({
    handleSubmit: hasCreatedBinding ? null : handleSubmit,
    onValidityChange: (isValid: boolean) => {
      props.onValidityChange(isValid);
    },
  }));

  const [schoolPlaceholder, setSchoolPlaceholder] = useState<string>();
  const handleSchoolSelect = (selectedOption: {
    value: string;
    label: string;
  }) => {
    if (selectedOption) {
      setValue('schoolName', selectedOption.value);
      setSchoolPlaceholder(selectedOption.label);
    }
  };

  useEffect(() => {
    formMethods.reset(props.defaultValues);
    if (props.defaultValues?.schoolName) {
      if (!getValues('schoolName')) {
        setValue('schoolName', props.defaultValues?.schoolName);
      }
      if (!schoolPlaceholder) {
        setSchoolPlaceholder(props.defaultValues?.schoolName);
      }
    }
  }, [props.defaultValues, formMethods]);

  useEffect(() => {
    props.onValidityChange(formMethods.formState.isValid);
  }, [formMethods.formState.isValid]);

  useEffect(() => {
    if (props.defaultValues?.hasCreatedBinding) {
      setHasCreatedBinding(true);
    }
  }, [props.defaultValues]);

  // useEffect(() => {
  //   if (props.disableEmail) {
  //     unregister('email');
  //   } else {
  //     register('email');
  //   }
  // }, [props.disableEmail]);

  return (
    <FormProvider {...formMethods}>
      <form ref={internalRef}>
        <div className="flex flex-col gap-[20px] mb-[30px]">
          <EmailInput
            name="email"
            placeholder="Student's email"
            disabled={hasCreatedBinding || props.disableEmail}
            handleBlur={(value) => {
              if (!props.defaultValues?.email) {
                checkEmail(value);
              }
            }}
          />
          {hasCreatedBinding ? (
            <p className="self-end">You have invited this student</p>
          ) : (
            <>
              {bindingData?.bindingAvailable && (
                <Button
                  className="ml-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    createBindingMutation.mutate({
                      email: getValues('email'),
                    });
                  }}
                  loading={createBindingMutation.isLoading}
                >
                  Ask this user to join me
                </Button>
              )}
            </>
          )}
        </div>
        <div
          className="flex flex-col gap-[30px]"
          style={{
            height:
              bindingData?.bindingAvailable || hasCreatedBinding
                ? '0'
                : props?.formHeight || '560px',
            overflow: 'hidden',
            transition: 'height 0.3s',
          }}
        >
          <Input name="fullName" placeholder="Student's full name" />
          <RadioInput name="gender" label="Gender" options={GendersList} />
          <DateInput
            name="birthday"
            placeholder="Student's date of birth"
            showBigWarning
          />

          <h3 className="text-2xl m-0">School Information</h3>
          <Input
            name="schoolZipCode"
            placeholder="School zip code"
            inputType={'number'}
          />
          <SchoolSelectDropdown
            name={'schoolName'}
            zipCodeName={'schoolZipCode'}
          />
          <Input
            inputType={'number'}
            name="gradeLevel"
            placeholder="Grade level"
            min={0}
            max={12}
          />
          {props.children}
        </div>
      </form>
    </FormProvider>
  );
});
