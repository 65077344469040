import * as yup from 'yup';
import { QuestionFields, SurveyTypes } from './types';

export const SurveyQuestionKeys = [
  SurveyTypes.TransformationInfoStep,
  SurveyTypes.SupportInfoStep,
  SurveyTypes.SupportRelationshipStep,
  SurveyTypes.MostChallengingStep,
];

export const SurveySchema = yup
  .object()
  .shape(
    Object.fromEntries(SurveyQuestionKeys.map((key) => [key, yup.boolean()])),
  );

export const SurveyQuestions: { [key in SurveyTypes]: QuestionFields } = {
  TransformationInfoStep: {
    question: 'Which of the following are priorities for your family?',
    answers: [
      {
        name: 'aCalmerAfterSchoolExperience',
        label: 'A calmer after school experience',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'cultivatingPositiveMindset',
        label: 'Cultivating a positive mindset about school',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'increasedSibLingHarmony',
        label: 'Increased sibling harmony',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'reducingStrugglesOverHomeWork',
        label: 'Reducing power struggles over homework',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'moreFamilyActivities',
        label: 'More time for family activities',
        inputType: 'checkbox',
        hasLabel: true,
      },
    ],
  },
  SupportInfoStep: {
    question:
      'What kind of support would you as a parent most like to receive? \n I want support…',
    answers: [
      {
        name: 'keepingTrackofHomework',
        label: 'Keeping track of my child’s homework assignments',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'creatingMoreStructure',
        label: 'Creating more structure around homework time',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'teachingHealthyHabitsToMyChildChildren',
        label: 'Teaching healthy study habits',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'encouragingMyChildsIndependenceAndSelfConfidence',
        label: 'Encouraging my child’s independence and self-confidence',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'effectivelyAdvocatingForMyChild',
        label: 'Effectively advocating for my child',
        inputType: 'checkbox',
        hasLabel: true,
      },
    ],
  },
  SupportRelationshipStep: {
    question:
      'What kind of support would you like your student to receive? \n They want support with…',
    answers: [
      {
        name: 'stayingOrganisedAndFocussed',
        label: 'Staying organized and focused',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'reducingOverwhelm',
        label: 'Reducing overwhelm',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'breakingDownComplexProjects',
        label: 'Breaking down complex projects into manageable steps',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'selfAdvocatorySkills',
        label: 'Self-advocacy skills',
        inputType: 'checkbox',
        hasLabel: true,
      },
      {
        name: 'remindersToSubmitWorkOnTime',
        label: 'Reminders to submit work on time',
        inputType: 'checkbox',
        hasLabel: true,
      },
    ],
  },
  MostChallengingStep: {
    question:
      'Is there anything else you would like to tell us about your child?',
    answers: [
      {
        name: 'mostChallenging',
        label: 'Enter your answer',
        inputType: 'textarea',
        hasLabel: true,
      },
    ],
  },
};
