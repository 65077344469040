import axios, {
  AxiosHeaderValue,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosStatic,
  AxiosError,
  InternalAxiosRequestConfig,
} from 'axios';
import { BaseSuccessResponse } from './types';
import { serverUrl } from '../../constants/environments';
import { credentialsService } from '../../utils/services/credentialsService';
import { useSnackbar } from '../../contexts/SnackbarContext';

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

interface ExtendedAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

class HttpClient {
  private readonly api: AxiosInstance;
  private isRefreshing = false;
  private failedQueue: Array<{
    resolve: (value: unknown) => void;
    reject: (reason?: any) => void;
  }> = [];

  private readonly config: AxiosRequestConfig = {
    baseURL: serverUrl,
    responseType: 'json',
    headers: {
      Accept: 'application/json',
    },
  };

  constructor(axiosStatic: AxiosStatic) {
    this.api = axiosStatic.create(this.config);
    this.initInterceptors();
  }

  public get<T, R = BaseSuccessResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.get(url, config);
  }

  public delete<T, R = BaseSuccessResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.delete(url, config);
  }

  public post<T, R = BaseSuccessResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.post(url, data, config);
  }

  public put<T, D, R = BaseSuccessResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.put(url, data, config);
  }

  public patch<T, D, R = BaseSuccessResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.api.patch(url, data, config);
  }

  private processQueue(error: any = null): void {
    this.failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(null);
      }
    });
    this.failedQueue = [];
  }

  private async refreshToken(): Promise<void> {
    try {
      const refreshToken = credentialsService.credentials?.refreshToken;
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      const response = await this.api.post<BaseSuccessResponse<RefreshTokenResponse>>('/auth/refresh', {
        refreshToken,
      });

      if (response.data.result?.accessToken && response.data.result?.refreshToken) {
        credentialsService.set({
          token: response.data.result.accessToken,
          refreshToken: response.data.result.refreshToken,
        });
        return;
      }
      throw new Error('Invalid response from refresh endpoint');
    } catch (error) {
      credentialsService.flush();
      throw error;
    }
  }

  private initInterceptors(): void {
    this.api.interceptors.request.use(
      function (config) {
        const url = config.url as string;
        const token = credentialsService.token;

        if (!/^(http|https):/i.test(url)) {
          const auth = `Bearer ${token}` as AxiosHeaderValue;
          config.headers = new AxiosHeaders({
            ...config.headers,
            Authorization: auth,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          });

          config.url = `${url.replace(/^\/+/, '')}`;

          return config;
        }

        return config;
      },
      function (error: AxiosError<{ message: string | string[] }>) {
        if (error.response?.status === 403) {
          const { handleNetworkError } = useSnackbar();
          handleNetworkError(error);
        }
        return Promise.reject(error);
      },
    );

    this.api.interceptors.response.use(
      (response) => response.data,
      async (error: AxiosError) => {
        const originalRequest = error.config as ExtendedAxiosRequestConfig;

        if (!originalRequest) {
          return Promise.reject(error);
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
          if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
              this.failedQueue.push({ resolve, reject });
            })
              .then(() => this.api(originalRequest))
              .catch((err) => Promise.reject(err));
          }

          originalRequest._retry = true;
          this.isRefreshing = true;

          try {
            await this.refreshToken();
            this.processQueue();
            return this.api(originalRequest);
          } catch (refreshError) {
            this.processQueue(refreshError);
            const { handleNetworkError } = useSnackbar();
            handleNetworkError(error as AxiosError<{ message: string | string[] }>);
            return Promise.reject(refreshError);
          } finally {
            this.isRefreshing = false;
          }
        }

        return Promise.reject(error);
      },
    );
  }
}

export const httpClient = new HttpClient(axios);
