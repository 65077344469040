import { AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { httpClient } from '../../api/http/http-client';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { StudentFields } from '../../components/RegisterStudentForm/RegisterStudentForm';

export interface Student {
  fullName: string;
  phone: string;
  gender: string;
  birthday: string;
  schoolZipCode: string;
  schoolName: string;
  gradeLevel: string;
  email: string;
  userUid: string;
  finishedRegistration: boolean;
}

export interface StudentResult extends StudentFields {
  uid: string;
  userUid: string;
}

export const getRegisteredStudents = async (): Promise<StudentResult[]> => {
  return httpClient.get<void, StudentResult[]>('/parents/students');
};

export const useGetRegisteredStudents = () => {
  return useQuery(['/parents/students'], getRegisteredStudents, {
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

const registerStudent = async (
  students: (StudentFields & { birthday: string })[],
): Promise<Student[]> => {
  return httpClient.post('/parents/students', students);
};

export const validateStudentEmail = async (email: string): Promise<boolean> => {
  return httpClient.post(`/parents/student/validate`, { email });
};

export const useRegisterStudent = (callbacks: {
  onSuccess?: (data: Student[]) => void;
  onError?: (error: AxiosError<{ message: string | string[] }>) => void;
}) => {
  const { handleNetworkError } = useSnackbar();
  const mutation = useMutation(
    (students: (StudentFields & { birthday: string })[]) =>
      registerStudent(students),
    {
      onSuccess: callbacks.onSuccess ?? (() => {}),
      onError: (error: AxiosError<{ message: string | string[] }>) => {
        handleNetworkError(error);
        callbacks?.onError?.(error);
      },
    },
  );

  return { mutation };
};
