import React, { useEffect, useState } from 'react';
import PreviousNextButtons from '../../components/PreviousNextButtons/PreviousNextButtons';
import RegistrationProgress from '../../components/RegistrationProgress/RegistrationProgress';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CheckboxInput from '../../components/CheckboxInput';
import { Accordion, AccordionTab } from 'primereact/accordion';
import {
  getRegisteredStudents,
  StudentResult,
} from '../RegisterStudent/useRegisterStudent';
import { useNavigate } from 'react-router-dom';
import {
  formatPrice,
  getSubscriptionDetails,
  purchaseStripe,
} from './useParentSubscription';
import { useSnackbar } from '../../contexts/SnackbarContext';
import { CONTACT_PAGE_URL } from '../ManageSubscription/ManageSubscriptions';

const schema = yup.object().shape({});

const ParentSubscription: React.FC = () => {
  const registrationSteps = {
    active: 8,
    total: 9,
  };

  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const prevStep = () => {
    navigate('/register/survey?question=4');
  };

  const [activeIndex, setActiveIndex] = useState<number | number[]>(2);
  const [studentsList, setStudentsList] = useState<StudentResult[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<{
    [uid: string]: boolean;
  }>({});

  const [primaryPrice, setPrimaryPrice] = useState<number>(0);
  const [secondaryPrice, setSecondaryPrice] = useState<number>(0);
  const [refundDays, setRefundDays] = useState<number>(0);
  const [stripePublicKey, setStripePublicKey] = useState<string>('');

  const [totalPrice, setTotalPrice] = useState<number>(0);

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const handlePayWithStripe = async () => {
    if (!totalPrice) {
      openSnackbar({
        type: 'error',
        message: 'Please select at least one student',
      });
      return;
    }

    const result = await purchaseStripe(
      stripePublicKey,
      selectedStudents,
      '/subscriptions/parent#canceled',
      '/register/invitations',
    );
    if (!result) {
      openSnackbar({
        type: 'error',
        message:
          'There are some issues with the payment gateway, please contact the administrator',
      });
      return;
    }
  };

  useEffect(() => {
    getSubscriptionDetails()
      .then((details) => {
        setPrimaryPrice(details.primaryPrice);
        setSecondaryPrice(details.secondaryPrice);
        setRefundDays(details.refundDays);
        setStripePublicKey(details.stripePublicKey);
      })
      .catch(() => {});

    getRegisteredStudents()
      .then((students) => {
        setStudentsList(students);
        if (!students.length) {
          navigate('/parent/home');
        }

        const initialSelectedState = students.reduce(
          (acc, student) => {
            acc[student.userUid] = true;
            return acc;
          },
          {} as { [uid: string]: boolean },
        );
        setSelectedStudents(initialSelectedState);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const selectedUids = Object.entries(selectedStudents)
      .filter(([_, v]) => v)
      .map(([uid, _]) => uid);

    let total = !selectedUids.length ? 0 : primaryPrice;
    if (selectedUids.length > 1) {
      total += (selectedUids.length - 1) * secondaryPrice;
    }
    setTotalPrice(total);
  }, [selectedStudents, primaryPrice, secondaryPrice]);

  const handleCheckboxChange = (uid: string, checked: boolean) => {
    setSelectedStudents((prev) => ({ ...prev, [uid]: checked }));
  };

  return (
    <div className="md:flex justify-end content-wrap md:min-h-screen md:justify-center flex flex-col subscription-container m-auto">
      <div className="pl-[20px] pt-[30px]">
        <PreviousNextButtons previousStep={prevStep} prevLabel="Back" />
      </div>
      <div className="p-5">
        <RegistrationProgress registrationSteps={registrationSteps} />
        <h2>Buy subscription for each student</h2>
        <FormProvider {...formMethods}>
          <form onSubmit={handlePayWithStripe}>
            {studentsList.map((student) => {
              const isSelected = selectedStudents[student.userUid];

              const selectedIndexes = Object.keys(selectedStudents).filter(
                (uid) => selectedStudents[uid],
              );
              const studentIndex = selectedIndexes.indexOf(student.userUid);

              return (
                <div
                  key={student.userUid}
                  className="student-select bg-white rounded-lg p-5 mb-2"
                >
                  <div className="flex">
                    <div className="flex-none w-10">
                      <CheckboxInput
                        name={`student-${student.userUid}`}
                        checked={isSelected}
                        onChange={() =>
                          handleCheckboxChange(
                            student.userUid,
                            !selectedStudents[student.userUid],
                          )
                        }
                      />
                    </div>
                    <div className="flex-initial w-full md:w-3/5">
                      <h3 className={'break-all w-full'}>{student.fullName}</h3>
                    </div>
                  </div>
                  <div
                    className={`flex pt-5 pl-10 justify-between items-center w-full ${
                      isSelected ? '' : 'not-active'
                    }`}
                  >
                    <div className="m-0">Annual Membership</div>
                    <div className="text-right m-0">
                      {isSelected && studentIndex === 0 ? (
                        <div className="ml-auto text-blue1">
                          ${formatPrice(primaryPrice)}
                        </div>
                      ) : Object.values(selectedStudents).some((val) => val) ? (
                        <div>
                          <div className="line-through ml-1 text-grey4">
                            ${formatPrice(primaryPrice)}
                          </div>
                          <div className="text-blue1">
                            ${formatPrice(secondaryPrice)}
                          </div>
                        </div>
                      ) : (
                        <div className="text-blue1">
                          ${formatPrice(primaryPrice)}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="total p-5">
              <div className="flex">
                <p className="w-4/5 m-0">Total</p>
                <p className="font-bold price w-1/5 text-right m-0 text-xl">
                  ${formatPrice(totalPrice)}
                </p>
              </div>
            </div>
            <div className="bg-white rounded-lg p-5 mb-2 mt-7">
              <p className="font-bold m-0">
                {refundDays}-day Money Back Guarantee
              </p>
              <p className="mt-2 mb-0">
                You can request a refund within 14 days after sign up.
              </p>
            </div>
            <Accordion
              className="elula-accordion mt-7"
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <AccordionTab
                className="elula-accordion-tab"
                header="Security and Privacy"
              >
                <p>
                  At ULURU, we are committed to safeguarding the security and
                  privacy of our users. This Security and Privacy Statement
                  outlines the measures we have in place to protect your
                  personal and financial information.
                </p>
                <p>
                  1. <b>Secure Subscription Processing with Stripe:</b>
                </p>
                <p>
                  {' '}
                  We partner with Stripe, a leading payment processor, to handle
                  subscription payments. Stripe is known for its security and
                  compliance with industry standards. Your payment information
                  is encrypted and stored securely, and we never have direct
                  access to your sensitive financial data.
                </p>
                <p>
                  2. <b>Data Protection and Privacy:</b>
                </p>
                <p>
                  {' '}
                  We take data protection and privacy seriously. Our platform
                  adheres to all relevant data protection laws, including the
                  General Data Protection Regulation (GDPR) for European users.
                  Your personal information is treated with the utmost care and
                  will not be shared with third parties without your explicit
                  consent.
                </p>
                <p>
                  3. <b>Account Security:</b>
                </p>
                <p>
                  {' '}
                  To enhance your account security, we recommend using strong,
                  unique passwords and enabling two-factor authentication (2FA)
                  whenever possible. Our platform provides the option to enable
                  2FA for an added layer of protection.
                </p>
                <p>
                  4. <b>User Data Access and Control:</b>
                </p>
                <p>
                  {' '}
                  You have the right to access and control your personal data.
                  You can view, edit, or delete your account information at any
                  time by accessing your account settings. Additionally, you can
                  request that we provide you with a copy of your data or delete
                  it entirely by contacting our support team.
                </p>
                <p>
                  5. <b>Data Encryption:</b>
                </p>
                <p>
                  {' '}
                  All data transmitted between your browser and our platform is
                  encrypted using industry-standard SSL/TLS encryption
                  protocols. This ensures that your information remains
                  confidential during transmission.
                </p>
                <p>
                  6. <b>Transparency and Communication:</b>
                </p>
                <p>
                  {' '}
                  We are committed to being transparent about our data
                  practices. If you have any questions or concerns about your
                  data or our security measures, please contact our support
                  team.
                </p>
                <p>
                  For any questions or concerns regarding security and privacy,
                  please contact our support team. Your trust and satisfaction
                  are of the utmost importance to us.
                </p>
              </AccordionTab>
              <AccordionTab
                className="elula-accordion-tab"
                header="Cancellation / Refund Policy"
              >
                <p>
                  We understand that circumstances may change, and the need to
                  cancel your subscription may occur. Please find our
                  cancellation policy below:
                </p>
                <p>
                  <b>Cancellation Window:</b> You can cancel your subscription
                  at any time, and the cancellation will be applied after
                  current billing period.
                </p>
                <p>
                  <b>How to Cancel:</b> To cancel your subscription, log in to
                  your ULURU account and navigate to the subscription settings
                  in profile menu.{' '}
                </p>
                <p>
                  <b>Auto-Renewal:</b> By default, subscriptions are set to
                  auto-renew at the end of each billing period.
                </p>
                <p>
                  <b>Contact Support:</b> If you have any questions or face
                  issues while canceling your subscription, please reach out to
                  our customer support team for assistance{' '}
                  <a href={CONTACT_PAGE_URL} target={'_blank'} rel="noreferrer">
                    Contact
                  </a>{' '}
                </p>
              </AccordionTab>
            </Accordion>
            <button
              type="button"
              onClick={handlePayWithStripe}
              className="p-button p-component p-[12px] px-[40px] text-center flex justify-center text-sm md:text-base mt-7"
            >
              Pay with <img src="/images/stripe.svg" alt="Stripe icon" />
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
export default ParentSubscription;
